<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a  @click="injet()">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="https://www.dct.cleaning/files/products_images/product_medium/0/388CD_1.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                INJET 

                </h4>
                
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a  @click="airjet()">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100% ;margin:auto"
                class="card-img-top img-fluid"
                src="https://www.dct.cleaning/files/products_images/product_medium/a/airjet_486.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 AIRJET




                </h4>
                
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a  @click="sonix()">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100% ;margin:auto"
                class="card-img-top img-fluid"
                src="https://www.dct.cleaning/files/products_images/product_medium/s/sonix355.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 SONIX




                </h4>
                
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        
      
      </div>
       
   
     
     
    
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
       injet(){
        window.location.href = "https://www.dct.cleaning/injet-cleaning-systems/";
    },
       airjet(){
        window.location.href = "https://www.dct.cleaning/airjet-cleaning-systems/";
    },
     sonix(){
      window.location.href = "https://www.dct.cleaning/sonix-cleaning-systems/";
    },
  },
};
</script>